import { useSelector } from 'react-redux'
import { useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'
import { useNavigate } from 'react-router-dom'

const Hook = () => {
  const navigate = useNavigate()
  const topics = useSelector((state) => state.app.topics)

  useEffect(() => {
    qwebApi({
      event: 'pushTopicsToClient',
      data: topics
    })
  }, [topics])

  // On update
  const onClientTopicClick = useCallback(
    ({ topicId, $$state = {} }) => {
      if (topicId) {
        navigate('/byTopicApps', {
          search: `topicId=${topicId}`,
          state: $$state,
        })
      }
    },
    []
  )
  useEffect(() => {
    on('onClientTopicClick', onClientTopicClick)
    return () => {
      off('onClientTopicClick', onClientTopicClick)
    }
  }, [onClientTopicClick])

  return topics

}

export default Hook
