import { useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const countryCode = useSelector((state) => state.system.countryCode)
  const recommendedApps = useSelector((state) => state.app.recommendedApps)

  const recommendedAppsForShow = useMemo(() => {
    let apps = []
    if (countryCode === 'JP') {
      apps = []
    } else {
      const topApps = [...recommendedApps].filter((app) => {
        return !!app.banner_url_v
      })
      const lastApps = [...recommendedApps].filter((app) => {
        return !app.banner_url_v
      })
      apps = topApps.concat(lastApps).slice(0, 12)
    }
    return apps
  }, [recommendedApps, countryCode])

  useEffect(() => {
    qwebApi({
      event: 'pushAndroidToClient',
      data: recommendedAppsForShow
    })
  }, [recommendedAppsForShow])

  return recommendedAppsForShow

}

export default Hook
