import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import Button from '@/components/common/Button/Button'
import { initCloudGamesApps } from '@/reducers/app/actions'
const EmptyContainer = () => {
  const dispatch = useDispatch()
  return <CommonHelpTip
    textTitle="canNotGetCloudGames"
    imgSrc={'noResults'}
    localSvg={true}
    extra={() => (
      <Button
        type="primary"
        className="margin-top-20 font16 minw200"
        onClick={() => dispatch(initCloudGamesApps())}
      >
        <FormattedMessage id="refresh" />
      </Button>
    )}
  />
}

export default EmptyContainer
