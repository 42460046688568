import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const Hook = () => {
  const topApps = useSelector((state) => state.app.topApps)
  const hotApps = useSelector((state) => state.app.hotApps)
  const rotationBanners = useSelector((state) => state.app.rotationBanners)
  const recommendedApps = useSelector((state) => state.app.recommendedApps)
  const featureApps = useSelector((state) => state.app.featureApps)
  // const suggestedApps = useSelector((state) => state.app.suggestedApps)
  // 设置过滤函数
  const allApps = useMemo(() => {
    const appGroups = [
      topApps,
      hotApps,
      rotationBanners,
      recommendedApps,
      featureApps,
      // suggestedApps,
    ].filter(Boolean)
    const allApps = appGroups.reduce((a, b) => [...a, ...b], [])
    const tempPackageNamesMap = {}
    // 去除重复游戏
    const filterApps = allApps.filter((app) => {
      if (tempPackageNamesMap[app.package_name || app.id]) {
        return false
      }
      tempPackageNamesMap[app.package_name || app.id] = true
      return app
    })
    return filterApps
  }, [topApps, hotApps, rotationBanners, recommendedApps, featureApps])
  return allApps
}

export default Hook
