import { useEffect, useCallback } from 'react'
import { on, off } from '@/apis/qwebApi'
import { useNavigate } from 'react-router-dom'
const Hook = () => {
  const navigate = useNavigate()
  // On route change
  const onRouter = useCallback(
    (route) => {
      navigate(route)
    },
    []
  )
  useEffect(() => {
    on('onRoute', onRouter)
    return () => {
      off('onRoute', onRouter)
    }
  }, [onRouter])
}

export default Hook
